<div class="hidden-div">
  <div class="tab">
    <div fxFlex="40" style="padding: 10px;">
      <h4 class="heading">JSON Viewer</h4>
      <span style="margin-left: 8px">
        <i>For Developers</i>
      </span>
    </div>
    <div fxFlex="40">
      <div class="action-btns">
        <div class="px-8">
          <div>
            Wrap Text
            <label class="switch">
              <input (change)="switchhorizontalScroll()" type="checkbox" [(ngModel)]="horizontalScrollCheck">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="px-8">
          <div>
            Dark Mode
            <label class="switch">
              <input (change)="switchDarkMode()" type="checkbox" [(ngModel)]="isDarkMode">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="px-8">
          <div>
            Large Font
            <label class="switch">
              <input (change)="changeFontSize()" type="checkbox" [(ngModel)]="fontCheck">
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="20">
      <div style="float: right; padding: 14px;">
        <button class="btn btn-info" data-toggle="modal" data-target="#about" data-backdrop="true" data-keyboard="true">About</button>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="work-area" id="workArea">

    <div fxFlex="{{editorWindowWidth}}">
      <app-editor (setEditDatatoNull)="setEditDatatoNull($event)"></app-editor>
    </div>

	<div fxFlex="0.5" id="dragBar" style="margin-top: 30px; background-color: darkgray; cursor: col-resize;">
		<div style="text-align: center; height: 100%; position: relative;">
			<span style="position: absolute; top: 45%; left: 0%; right: 0%;">|</span>
		</div>
	</div>

    <div fxFlex="{{viewerWindowWidth}}">
      <app-viewer (openPopup)="openPopup($event)" [editData]="editData" [isDarkMode]="isDarkMode"></app-viewer>
    </div>

    <div fxFlex="{{adWindowWidth}}">
      <app-google-add></app-google-add>
    </div>

  </div>
</div>

<app-json-modal (setEditDatatoNull)="setEditDatatoNull($event)"></app-json-modal>

<div id="about" class="modal fade" role="dialog" tabindex="10">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content" id="aboutModal">
        <div class="modal-header">
            <h4 class="modal-title">About</h4>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body" style="font-size: 13px; font-family: monospace;">
          <div>JSON Viewer is a tool to View, Beautify, Update and Filter the JSON Data in unambiguous and comprehensible format.</div><br>
          <div>Author: Abhishek Pankar</div><br>
          <div>Report issues <a href="mailto:abhishek.pankar11@gmail.com">here</a></div><br>
          <br>
          <h5>Instructions:</h5>
          <ol>
            <li>Paste your JSON on the editor (left-side) of the screen. Formatted JSON will be displayed on the viewer (right-side)</li>
            <li>User can edit JSON by selecting the checkbox displayed before the arrays and then click on `Edit` button. This operation is similar to JavaScript's <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/map">map</a> method</li>
            <li>On selection, the parent and child checkboxes will be disabled because user can select the parent directly to edit the child.</li>
            <li>The search filter on the right side will search your entire JSON, filter it and return the data. This operation is similar to JavaScript's <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter">filter</a> operation</li>
            <li>`Get Sample JSON` button on the editor gives a basic example of JSON</li>
            <li>`Wrap Text` option on the top is enabled by default which puts the long text to the next line.</li>
          </ol>
          <br>
          <h5>Privacy and Data Policy:</h5>
            This application does not store nor tracks any data in any way.
        </div>
        <div class="modal-footer">
            <button type="button" data-dismiss="modal">Close</button>
        </div>
    </div>

  </div>
</div>
