import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JsonCarrierService } from '../services/json-carrier/json-carrier.service';

@Component({
    selector: 'app-json-modal',
    templateUrl: './json-modal.component.html',
    styleUrls: ['./json-modal.component.css']
})
export class JsonModalComponent implements OnInit {

    constructor(private jsonCarrierService: JsonCarrierService) {
        this.jsonCarrierService.sharedJson.subscribe(data => {
        this.jsonData = data;
        })
    }

    jsonData: any;

    @Output() setEditDatatoNull = new EventEmitter();

    ngOnInit(): void {
        let {backgroundColor, color} = document.getElementById('workArea').style;
        document.getElementById('modalContent').style.backgroundColor = backgroundColor;
        document.getElementById('modalContent').style.color = color;
    }

    editData: Array<any>;
    applyDisabled: boolean[] = [];
    isApplyDisabled: boolean = false;

    showData = (data) => {
        this.editData = data.map(d => ({ ...d, expression: 'item' }));
    }

    changeEvent = (path, exp, pIndex) => {
        try {
            let expression = exp.replace('JSON', 'this.jsonData')
            let index = 0;
            let item = eval(path)[0];

            const value = eval(expression);
            if (value == undefined || expression.match(/;+/g) != null) {
                throw 'Expression not allowed'
            }
            document.getElementById(`modalTextArea${pIndex}`).style.borderWidth = '';
            document.getElementById(`modalTextArea${pIndex}`).style.borderColor = '';
            this.applyDisabled[pIndex] = false;
        } catch (error) {
            document.getElementById(`modalTextArea${pIndex}`).style.borderWidth = '1.5px';
            document.getElementById(`modalTextArea${pIndex}`).style.borderColor = 'red';
            this.applyDisabled[pIndex] = true;
        }
        this.isApplyDisabled = !this.applyDisabled.every((value) => value == false);
    }

    removeSelection = (index, data) => {
        data.selectedExpression.splice(index, 1);
    }

    apply = () => {
        try {
            this.editData.forEach(value => {
                let expression = value.expression.replace('JSON', 'this.jsonData');
                let ref = eval(value.originalPath);

                let temp = [];

                ref.forEach((item, index) => {
                    let output = eval(`(${expression})`);
                    temp.push(JSON.parse(JSON.stringify(output)));
                })

                temp.forEach((item, index) => ref[index] = item);
            })
            this.jsonCarrierService.setJson(this.jsonData);
            this.editData = [];
            this.setEditDatatoNull.emit(null);
        } catch (err) {
            this.setEditDatatoNull.emit(err);
        }
    }
}
