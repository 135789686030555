import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-google-add',
  templateUrl: './google-add.component.html',
  styleUrls: ['./google-add.component.css']
})
export class GoogleAddComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
