import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { JsonCarrierService } from '../services/json-carrier/json-carrier.service';
import JSONData from "./sample.json";

interface MyEvent extends Event {
	target: HTMLInputElement
}

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

    constructor(private jsonService: JsonCarrierService) { }

    showMessage: boolean = false;
    data = JSONData;

    @Output() setEditDatatoNull = new EventEmitter();

    ngOnInit(): void {
		const fileUploadElement = document.getElementById('fileUpload') as HTMLElement & HTMLInputElement;
		fileUploadElement.addEventListener('change', (event: MyEvent) => {
			console.log(event.target.files[0]);
			let fileReader = new FileReader();
			fileReader.onload = (ev) => {
				this.jsonString = ev.target.result;
				this.onChange();
				fileUploadElement.value = null;
			}
			fileReader.readAsText(event.target.files[0]);
		})
    }

    jsonString: any;

    formatJSON = (space) => {
        try {
            if (this.jsonString) {
                this.jsonString = JSON.stringify(JSON.parse(this.jsonString), undefined, space);
            } else {
                this.showMessage = false;
            }
        } catch (error) {
            alert('Invalid JSON String!')
        }
    }

    clearScreen = () => {
        this.jsonString = null;
        this.showMessage = false;
        this.jsonService.setJson(null);
        this.setEditDatatoNull.emit(null);
    }

    onChange = () => {
        try {
            let data = JSON.parse(this.jsonString);
            this.jsonService.setJson(data);
            this.showMessage = false;
            this.setEditDatatoNull.emit(null);
        } catch (error) {
            this.jsonService.setJson(null);
            if (this.jsonString) {
                this.showMessage = true;
            }
            this.setEditDatatoNull.emit(null);
        }
    }

    getSampleJSON = () => {
        this.jsonString = JSON.stringify(this.data, undefined, 4);
        this.jsonService.setJson(JSON.parse(this.jsonString));
        this.showMessage = false;
        this.setEditDatatoNull.emit(null);
    }
}
