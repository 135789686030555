import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class JsonCarrierService {

  private data = new BehaviorSubject(null);

  sharedJson = this.data.asObservable();
  
  constructor() { }

  setJson = (jsonData) => {
    this.data.next(jsonData);
  }
}
