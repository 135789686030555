import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JsonFilterService {

  constructor() { }

  private data = new BehaviorSubject(null);

  filterData = this.data.asObservable();

  setFilter = (data) => {
    this.data.next(data);
  }
}
