<mat-accordion>
    <mat-expansion-panel hideToggle="true" [expanded]="jsonInput.buttonName=='remove'">
      <mat-expansion-panel-header (click)="toggleTree($event, jsonInput)">
        <mat-panel-title style="padding-left: 7px;">
          <span class="checkbox-style">
            <input style="margin-left: -7px;" type="checkbox" *ngIf="jsonInput.type=='array' && mapFilter" (change)="checkToEdit(jsonInput.isChecked, jsonInput)" [(ngModel)]="jsonInput.isChecked" (click)="stopPropogation($event)" [disabled]="(jsonInput.parentCheck && !jsonInput.isChecked) || jsonInput.isDisabled.length > 0">
          </span>
          <mat-icon>{{jsonInput.buttonName}}</mat-icon>
          <span *ngIf="jsonInput.type=='object'" style="color: #9797e0; font-weight: bolder;"><b>{{'{}'}}</b></span>
          <span *ngIf="jsonInput.type=='array'" style="color: #9797e0; font-weight: bolder;"><b>[]</b></span>
          <span style="margin-left: 4px;">{{jsonInput.key}}</span>&nbsp;
		  <span style="color: cadetblue;">({{jsonInput.value.length}})</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <ul class="main-obj list-type">
           <div class="obj-list" *ngFor="let item of jsonInput.value | search: searchData; trackBy: trackChanges" [ngSwitch]="item.type">
              <li *ngSwitchCase="'string'" class="string-color" style="padding-left: 7px;">
                {{item.key}}: "{{item.value}}"
              </li>
              <li *ngSwitchCase="'null'" class="null-color" style="padding-left: 7px;">
                {{item.key}}: null
              </li>
              <li *ngSwitchCase="'number'" class="number-color" style="padding-left: 7px;">
                {{item.key}}: {{item.value}}
              </li>
              <li *ngSwitchCase="'boolean'" class="boolean-color" style="padding-left: 7px;">
                {{item.key}}: {{item.value}}
              </li>
              <li style="list-style: none; margin-left: -52px; padding-left: 12px;" *ngSwitchCase="'object'">
                  <app-json-tree [jsonInput]="item" [mapFilter]="mapFilter" [editData]="editData" (childCheckValue)="childCheckFn($event)"></app-json-tree>
              </li>
              <li style="list-style: none; margin-left: -52px; padding-left: 12px;" *ngSwitchCase="'array'">
                <app-json-tree [jsonInput]="item" [mapFilter]="mapFilter" [editData]="editData" (childCheckValue)="childCheckFn($event)"></app-json-tree>
            </li>
          </div>
       </ul>
      </ng-template>
    </mat-expansion-panel>
</mat-accordion>