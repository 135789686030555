import { Component, ViewChild } from '@angular/core';
import { JsonModalComponent } from './json-modal/json-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

	fontCheck: boolean;
	isDarkMode: boolean;
	horizontalScrollCheck: boolean = false;
	editData: Array<any> = [];
	editorWindowWidth = 40;
	viewerWindowWidth = 40;
	adWindowWidth = 19.5;

  	@ViewChild(JsonModalComponent) jsonModalChild: JsonModalComponent;

	ngOnInit () {
		this.switchhorizontalScroll();

		if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			this.isDarkMode = true;
			this.switchDarkMode();
		}

		window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
			this.isDarkMode = e.matches == true;
			this.switchDarkMode();
		});

		const mouseMoveCallback = (e: MouseEvent) => {

			const mousePostition = Math.ceil((e.screenX * 100) / window.innerWidth);
			e.preventDefault();
			if (mousePostition > 13 && mousePostition < 67) {
				this.editorWindowWidth = (e.screenX * 99.5) / window.innerWidth;
				this.viewerWindowWidth = 80 - this.editorWindowWidth;
				const element = document.getElementsByClassName('border-box') as HTMLCollectionOf<HTMLElement>;
				element[0].style.width = this.editorWindowWidth + '%';
				element[1].style.width = this.viewerWindowWidth + '%';
				if (navigator.userAgent.indexOf('Firefox') + 1) {
					document.getElementById('searchData').style.width = `${((window.innerWidth * this.viewerWindowWidth) / 100) - 82.4 - 40 - 60}px`;
				} else {
					document.getElementById('searchData').style.width = `${((window.innerWidth * this.viewerWindowWidth) / 100) - 77.65 - 36.31 - 60}px`;
				}
			}
		};

		document.getElementById('dragBar').addEventListener('mousedown', (event) => {
			event.preventDefault();
			document.addEventListener('mousemove', mouseMoveCallback);
		})

		document.addEventListener('mouseup', (event) => {
			document.removeEventListener('mousemove', mouseMoveCallback);
		})

	}

	changeFontSize = () => {
		if (this.fontCheck) {
			document.getElementById("fontSize1").style.fontSize = "14px";
			document.getElementById("fontSize2").style.fontSize = "14px";
		} else {
			document.getElementById("fontSize1").style.fontSize = "12px";
			document.getElementById("fontSize2").style.fontSize = "12px";
		}
	}

	switchDarkMode = () => {
		if (this.isDarkMode) {
			document.getElementById("workArea").style.backgroundColor = "#353535";
			document.getElementById("workArea").style.color = "white";
			document.getElementById('modalContent').style.backgroundColor = "#353535";
			document.getElementById('modalContent').style.color = "white";
			document.getElementById('aboutModal').style.backgroundColor = "#353535";
			document.getElementById('aboutModal').style.color = "white";
		} else {
			document.getElementById("workArea").style.backgroundColor = "inherit";
			document.getElementById("workArea").style.color = "inherit";
			document.getElementById('modalContent').style.backgroundColor = "";
			document.getElementById('modalContent').style.color = "";
			document.getElementById('aboutModal').style.backgroundColor = "";
			document.getElementById('aboutModal').style.color = "";
		}
	}

	switchhorizontalScroll = () => {
		if (this.horizontalScrollCheck) {
			(document.getElementById("editorTextarea") as HTMLTextAreaElement).wrap = "soft";
			document.getElementById("fontSize2").style.whiteSpace = "";
		} else {
			(document.getElementById("editorTextarea") as HTMLTextAreaElement).wrap = "off";
			document.getElementById("fontSize2").style.whiteSpace = "nowrap";
		}
	}

	openPopup = (data) => {
		this.editData = data;
		this.jsonModalChild.showData(this.editData);
	}

	setEditDatatoNull = (err) => {
		if (err) {
			alert('Invalid Expression!')
		} else {
			this.editData = [];
		}
	}
}
