import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule} from '@angular/flex-layout';
import { EditorComponent } from './editor/editor.component';
import { ViewerComponent } from './viewer/viewer.component';
import { JsonTreeComponent } from './viewer/json-tree/json-tree.component';
import { GoogleAddComponent } from './google-add/google-add.component';
import { MatIconModule} from '@angular/material/icon';
import { FormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import { JsonModalComponent } from './json-modal/json-modal.component';
import { SearchPipe } from './pipes/search.pipe';
@NgModule({
  declarations: [
    AppComponent,
    EditorComponent,
    ViewerComponent,
    JsonTreeComponent,
   GoogleAddComponent,
   JsonModalComponent,
   SearchPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    MatIconModule,
    FormsModule,
    MatExpansionModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
