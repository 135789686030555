import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { JsonCarrierService } from '../services/json-carrier/json-carrier.service';
import { JsonFilterService } from '../services/json-filter/json-filter.service';

@Component({
    selector: 'app-viewer',
    templateUrl: './viewer.component.html',
    styleUrls: ['./viewer.component.css']
})
export class ViewerComponent implements OnInit {

    constructor(private jsonService: JsonCarrierService, private jsonFilterService: JsonFilterService) {
        this.jsonService.sharedJson.subscribe(data => {
            this.jsonString = data;
            if (!data) {
                this.editData = [];
            }
            this.searchData = null;
            this.jsonFilterService.setFilter(this.searchData);
            this.convert();
        });

        window.addEventListener('resize', () => {
            if (navigator.userAgent.indexOf('Firefox') + 1) {
                document.getElementById('searchData').style.width = `${(window.innerWidth * 0.4) - 82.4 - 40 - 60}px`;
            } else {
                document.getElementById('searchData').style.width = `${(window.innerWidth * 0.4) - 77.65 - 36.31 - 60}px`;
            }
        })
    }

    jsonString: any;
    mapFilter: boolean = true;
    searchData: any;
    @Input() editData: Array<any>;
	@Input() isDarkMode: boolean;
    @Output() openPopup = new EventEmitter();

    jsonData = {
        key: 'JSON',
        value: [],
        type: 'object',
        buttonName: 'add',
        isDisabled: [],
        originalPath: 'this.jsonData'
    }

    ngOnInit(): void {
        if (navigator.userAgent.indexOf('Firefox') + 1) {
            document.getElementById('searchData').style.width = `${(window.innerWidth * 0.4) - 82.4 - 40 - 60}px`;
        } else {
            document.getElementById('searchData').style.width = `${(window.innerWidth * 0.4) - 77.65 - 36.31 - 60}px`;
        }
    }

    convert = (order?: 'asc' | 'desc') => {
        try {
            this.jsonData.type = typeof this.jsonString;
            if (Array.isArray(this.jsonString)) {
                this.jsonData.type = 'array';
            }
            this.jsonData.value = this.convertObjectToArray(Object.assign({}, this.jsonString), this.jsonData.originalPath, order);
            this.jsonData.isDisabled = [];
            this.jsonData.buttonName = 'remove';
            if (!(this.jsonData.value.length)) {
                this.jsonData.buttonName = 'add';
            }
        } catch (error) {
            alert('Invalid JSON String!');
        }
    }

    collapseAll = (data) => {
        data.buttonName = 'add';
        if (data.key == 'JSON') {
            data.buttonName = 'remove';
        }
        if (Array.isArray(data.value)) {
            data.value.forEach(item => {
                this.collapseAll(item);
            })
        }
    }

    expandAll = (data) => {
        data.buttonName = 'remove';
        if (Array.isArray(data.value)) {
            data.value.forEach(item => {
                this.expandAll(item);
            })
        }
    }

    convertObjectToArray = (object, originalPath, order?: 'asc' | 'desc') => {
		let arr = [];
        if (order) {
            const keys = Object.keys(object);
            keys.sort();
            if (order == 'desc') {
                keys.reverse();
            }
            const newObj = {};
            for (const key of keys) {
                newObj[key] = object[key];
            }
            object = JSON.parse(JSON.stringify(newObj));
            
        }
		for (const key in object) {
            let tempPath = originalPath + (Number.isNaN(parseInt(key)) ? `['${key}']` : `[${key}]`);
            let type : any = typeof object[key];
            if (object[key] == null) {
                type = 'null';
            } else if (type == 'object' && !Array.isArray(object[key])) {
                object[key] = this.convertObjectToArray(Object.assign({}, object[key]), tempPath, order);
            } else if (type == 'object' && Array.isArray(object[key])) {
                object[key] = object[key].map((item: any, index) => {
                    if (typeof item == 'object') {
                        let type = 'object';
                        if (Array.isArray(item)) {
                            type = 'array';
                        }
                        return {
                            key: index,
                            value: this.convertObjectToArray(Object.assign({}, item), tempPath + `[${index}]`, order),
                            type: type,
                            buttonName: 'add',
                            // path: `${path}.["value"].[${arr.length}].[${index}]`,
                            originalPath: tempPath + `[${index}]`,
                            isChecked: false,
                            isDisabled: [],
                            parentCheck: false
                        };
                    } else {
                        return {
                            key: index,
                            value: item,
                            type: typeof item,
                            buttonName: 'add',
                            // path: `${path}.["value"].[${arr.length}].[${index}]`,
                            originalPath: tempPath + `[${index}]`,
                            isChecked: false,
                            isDisabled: [],
                            parentCheck: false
                        };
                    }
                })
                type = 'array';
            }

            arr.push({
                key: key,
                value: object[key],
                type: type,
                buttonName: 'add',
                // path: `${path}.["value"].[${arr.length}]`,
                originalPath: tempPath,
                isChecked: false,
                isDisabled: [],
                parentCheck: false
            });
		}
		return arr;
    };

    editJson = () => {
        if (this.jsonString) {
            this.mapFilter = !this.mapFilter;
        } else {
            this.mapFilter = false;
        }
    }

    action = () => {
        this.openPopup.emit(this.editData);
    }

    changeFilterData = () => {
        this.jsonFilterService.setFilter(this.searchData);
    }
}
