<div class="border-box">
	<img title="Remove Whitespace" src="../../assets/icons/remove_white_space_ 1.svg" alt="" class="menu-buttons" (click)="formatJSON(0)">
	<img style="padding: 4px;" title="Clear" src="../../assets/icons/delete_4.svg" alt="" class="menu-buttons" (click)="clearScreen()">
	<img title="Beautify" src="../../assets/icons/beautify_1.svg" alt="" class="menu-buttons" (click)="formatJSON(4)">
	<img title="Get Sample JSON" src="../../assets/icons/json_file_1.svg" alt="" class="menu-buttons" (click)="getSampleJSON()">
	<label for="fileUpload" style=" margin: 0px;"><img title="Upload File" src="../../assets/icons/file_upload_1.svg" alt="" class="menu-buttons"></label>
	<input type="file" name="" id="fileUpload" style="display: none;">
	<div *ngIf="showMessage" class="invalid-text"><i>Invalid JSON..!!</i></div>
</div>
<div class="border-text top-border">
  <div class="border-viewer" id="fontSize1">
	  <textarea id="editorTextarea" placeholder="Paste your JSON here..." [(ngModel)]="jsonString" (ngModelChange)="onChange()" spellcheck="false"></textarea>
  </div>
</div>