import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {

    constructor() { }

    transform(value: any, args: string): unknown {
        if (!args) {
            return value;
        } else {
            args = args.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            let regex = new RegExp(`${args}+`, `gim`)
            return value.filter(item => {
                let found = searchFn(item.value, regex) || Boolean(item.key.toString().match(regex));
                return Boolean(found);
            });
        }
    }
}

function searchFn (value, regex) {

    if (typeof value != 'object' || value == null) {
        return Boolean(JSON.stringify(value).match(regex));
    } else {
		const temp = value.map((item) => {
            return searchFn(item.value, regex) || Boolean(item.key.toString().match(regex));
        })

		if (temp && temp.length) {
			return temp.reduce((item, result) => item || result);
		}
		return false;
    }
}