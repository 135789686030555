<div class="modal fade" id="myModal" role="dialog" tabindex="11">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content" id="modalContent">
            <div class="modal-header">
                <h4 class="modal-title">Edit JSON</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <h4>Map Array:</h4>
                <div style="font-size: 14px; padding-bottom: 20px; background-color: inherit;" *ngFor="let data of editData; index as pIndex;">
                    <div fxLayout="row" style="background-color: inherit;">
                        <div fxFlex="2">
                            <span>{{pIndex + 1}}. </span>
                        </div>
                        <div fxFlex="98" style="background-color: inherit;">

                            <code>{{data.originalPath.replace('this.jsonData', 'JSON')}} = {{data.originalPath.replace('this.jsonData', 'JSON')}}.map((item, index) => {{'{'}}<br>
                                &nbsp;&nbsp;&nbsp;&nbsp;return&nbsp;
                            </code>&nbsp;
                            <br>
                            <textarea id="modalTextArea{{pIndex}}" cols="130" rows="5" spellcheck="false" [(ngModel)]="data.expression" (ngModelChange)="changeEvent(data.originalPath, data.expression, pIndex)" placeholder="Your Expression"></textarea><br>

                            <code>
                                {{'}'}})
                            </code>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" data-dismiss="modal" (click)="apply()" [disabled]="isApplyDisabled">Apply</button>
            </div>
        </div>

    </div>
</div>