import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { JsonFilterService } from 'src/app/services/json-filter/json-filter.service';

@Component({
  selector: 'app-json-tree',
  templateUrl: './json-tree.component.html',
  styleUrls: ['./json-tree.component.css']
})

export class JsonTreeComponent implements OnInit {
	@Input() jsonInput: any;
	@Input() mapFilter: any;
	@Input() editData: Array<any>;
	@Output() childCheckValue = new EventEmitter();
	
	constructor(private jsonFilterService: JsonFilterService){
        this.jsonFilterService.filterData.subscribe(data => {
            this.searchData = data;
        })
	}
	
	searchData: any;
	jsonData: any;
	check: any;
	childCheck: boolean;

	ngOnInit(){
	}
	
	toggleTree(event, toggleButton) {
		event.stopPropagation();
		if(toggleButton.buttonName == 'add')  
			toggleButton.buttonName = 'remove';
		else
			toggleButton.buttonName = 'add';
	};

	trackChanges = (index) => {
        return index;
	}

	disableAllChildOnCheck = (check, data) => {
		data.value.forEach((item, i) => {
			data.value[i].parentCheck = check;
			if (Array.isArray(data.value[i].value)) {
				this.disableAllChildOnCheck(check, data.value[i])
			}
		})
	}
	
	checkToEdit = (check, data) => {
		this.disableAllChildOnCheck(check, data);

		this.childCheckValue.emit({
			isChecked : check,
			originalPath: data.originalPath
		});

		if (check) {
			this.editData.push(data);
		} else {
			let index = this.editData.findIndex(item => item.originalPath == data.originalPath);
			this.editData.splice(index, 1);
		}
	}

	stopPropogation = (event) => {
		event.stopPropagation();
	}

	childCheckFn = (data) => {
		if (!this.jsonInput.isDisabled) {
			this.jsonInput.isDisabled = [];
		}
		if (data.isChecked) {
			this.jsonInput.isDisabled.push(data.originalPath);
		} else {
			this.jsonInput.isDisabled.splice(this.jsonInput.isDisabled.indexOf(data.originalPath), 1);
		}

		this.childCheck = this.jsonInput.isDisabled.length > 0;
		this.childCheckValue.emit(data);
	}
}
