<div class="border-box">
    <button [ngClass]="{ 'disabled-button': (!(editData.length && mapFilter) && isDarkMode) }" (click)="action()" [disabled]="!(editData.length && mapFilter)" data-toggle="modal" data-target="#myModal" data-backdrop="true" data-keyboard="true">Edit</button>
	<img title="Collapse All" src="../../assets/icons/collapse_1.svg" alt="" class="new-menu-buttons" (click)="collapseAll(jsonData)">
    <img title="Expand All" src="../../assets/icons/expand_1.svg" alt="" class="new-menu-buttons" (click)="expandAll(jsonData)">
    <!-- <button (click)="editJson()" [disabled]="!jsonString">Edit Mode: {{mapFilter}}</button> -->
	<img title="Sort Ascending" src="../../assets/icons/sort_asc_1.svg" alt="" class="menu-button-sort-asc" (click)="convert('asc')">
	<img title="Sort Descending" src="../../assets/icons/sort_desc_1.svg" alt="" class="new-menu-buttons" (click)="convert('desc')">
    <input type="search" id="searchData" [(ngModel)]="searchData" style="background-color: inherit; color: inherit; outline: none; font-family: monospace; border: none" placeholder="Search" (ngModelChange)="changeFilterData()">
</div>
<div class="top-border">
    <div class="v-scroll border-viewer" id="fontSize2">
        <app-json-tree [jsonInput]="jsonData" [mapFilter]="mapFilter" [editData]="editData"></app-json-tree>
    </div>
</div>